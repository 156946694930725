import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import routes from '../../routes';

import { IconContact, IconFind, IconMenu } from '../../atoms/Icons';

import { navigationItems } from './items';

const secindaryNavigationItems: navigationItems = [
  // {
  //     "icon": "icon-subscribe",
  //     "text": "Sitemap"
  //   },
  {
    icon: IconFind,
    text: 'Намери църква',
    url: routes.churches
  },
  {
    icon: IconContact,
    text: 'Контакт',
    url: routes.contact
  }
];
const SecondaryNavigation = ({
  setOpenDrawerMenu
}: {
  setOpenDrawerMenu: (isActive: boolean) => void;
}) => (
  <nav className="c-secondary-nav" role="navigation">
    <ul className="c-secondary-nav__list">
      {secindaryNavigationItems.map((item, i) => (
        <li
          key={i}
          className={classNames(
            'c-secondary-nav__list-item',
            { 'is-priority': item.priority },
            { 'has-subnav': item.subnav }
          )}
        >
          <NavLink
            to={item.url || ''}
            className="c-secondary-nav__link u-font--secondary-nav u-color--gray u-theme--link-hover--base"
          >
            {item.icon && (
              <span className="u-icon u-icon--xs u-path-fill--gray">
                {<item.icon title="" />}
              </span>
            )}
            {item.text}
          </NavLink>

          {item.subnav && (
            <>
              <span className="c-subnav__arrow o-arrow--down"></span>
              <ul className="c-secondary-nav__subnav c-subnav">
                {item.subnav.items.map((subItem, j) => (
                  <li
                    key={j}
                    className="c-secondary-nav__subnav__list-item c-subnav__list-item u-background-color--gray--light"
                  >
                    <NavLink
                      to={subItem.url || ''}
                      className={({ isActive }) =>
                        classNames(
                          'c-secondary-nav__subnav__link',
                          'c-subnav__link u-color--gray',
                          'u-theme--link-hover--base',
                          { active: isActive }
                        )
                      }
                    >
                      {subItem.text}
                    </NavLink>
                  </li>
                ))}
              </ul>
            </>
          )}
        </li>
      ))}

      {/* 
       <li className="c-secondary-nav__list-item c-secondary-nav__list-item__search c-secondary-nav__list-item__toggle js-toggle-menu js-toggle-search is-priority">
         <a href="#" className="c-secondary-nav__link u-font--secondary-nav u-color--gray u-theme--link-hover--base">
           <span className="u-icon u-icon--xs u-path-fill--gray">{% include '@atoms/icons/icon-search.twig' %}</span>Search
         </a>
       </li>*/}
      <li className="c-secondary-nav__list-item c-secondary-nav__list-item__menu c-secondary-nav__list-item__toggle js-toggle-menu is-priority">
        {/*eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
        <a
          href="#"
          onClick={() => setOpenDrawerMenu(true)}
          className="c-secondary-nav__link u-font--secondary-nav u-color--gray u-theme--link-hover--base"
        >
          <span className="u-icon u-icon--xs u-path-fill--gray">
            <IconMenu />
          </span>
          Меню
        </a>
      </li>
    </ul>
  </nav>
);

export default SecondaryNavigation;
