import { useState } from 'react';
import { Link } from 'react-router-dom';
import routes from '../routes';
import { ReactComponent as Logo } from './img/sdabg.net-logo.svg';
import DrawerNavigation from './navigation/DrawerNavigation';
import PrimaryNavigation from './navigation/PrimaryNavigation';
import SecondaryNavigation from './navigation/SecondaryNavigation';

const Header = () => {
  const [openDrawerMenu, setOpenDrawerMenu] = useState(false);
  return (
    <>
      <header className="c-header" role="banner" id="header">
        <div className="c-header--inner">
          <div className="c-header__nav-secondary">
            {/* include '@molecules/navigation/secondary-navigation.twig' */}
            <SecondaryNavigation setOpenDrawerMenu={setOpenDrawerMenu} />
          </div>
          <div className="c-header__logo c-logo c-header__logo--wide">
            <Link
              to={routes.home}
              className="c-logo__link u-theme--path-fill--base"
            >
              <Logo role="img" aria-label="sdabgnet-logo" />
              {/* height of logo symbol to be 100px */}
            </Link>
          </div>
          <div className="c-header__nav-primary">
            {/* '@molecules/navigation/primary-navigation.twig'  */}
            <PrimaryNavigation />
          </div>
        </div>
      </header>

      {/*'@molecules/navigation/drawer-navigation.twig' with drawer */}
      <DrawerNavigation
        isActive={openDrawerMenu}
        setActive={setOpenDrawerMenu}
      ></DrawerNavigation>
    </>
  );
};
export default Header;
