import routes from './routes';
import React, { Suspense } from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';

import Layout from './layout/Layout';

const NotFound = React.lazy(() => import('./pages/NotFound'));
const Home = React.lazy(() => import('./pages/Home'));
const Lesson = React.lazy(() => import('./pages/lesson/Lesson'));
const Churches = React.lazy(() => import('./pages/Churches'));
const Books = React.lazy(() => import('./pages/Books'));
const Events = React.lazy(() => import('./pages/Events'));
const Institutions = React.lazy(() => import('./pages/Institutions'));

const Router = () => (
  <Suspense fallback={<h2>Зареждане...</h2>}>
    <BrowserRouter>
      <Routes>
        <Route path={routes.home} element={<Layout />}>
          <Route index element={<Home />} />
          <Route path={routes.churchLife('lesson')} element={<Lesson />} />
          <Route path={routes.churchLife('events')} element={<Events />} />
          <Route path={routes.lesson} element={<Lesson />} />
          <Route path={routes.churches} element={<Churches />} />
          <Route path={routes.info('churches')} element={<Churches />} />
          <Route path={routes.resources('books')} element={<Books />} />
          <Route
            path={routes.health('institutions')}
            element={<Institutions />}
          />
          {/* <Route path="teams" element={<Teams />}>
          <Route path=":teamId" element={<Team />} />
          <Route path="new" element={<NewTeamForm />} />
          <Route index element={<LeagueStandings />} />
        </Route> */}
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </BrowserRouter>
  </Suspense>
);
export default Router;
