import { useEffect, useState } from 'react';

const scriptsToLoad = [
  //see https://alps.adventist.io/v3/?p=viewall-pages-misc
  '//cdn.adventist.org/alps/3/latest/js/head-script.min.js',
  '//cdn.adventist.org/alps/3/latest/js/script.min.js'
];

const loadScript = (url: string, id: string) => {
  return new Promise<void>((resolve, reject) => {
    if (!document.getElementById(id)) {
      const script = document.createElement('script');
      script.type = 'text/javascript';

      script.onload = function () {
        resolve();
      };
      script.id = id;
      script.src = url;
      document.getElementsByTagName('head')[0].appendChild(script);
    }
  });
};

const Footer = () => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!loaded) {
      setLoaded(true);
      Promise.all(
        scriptsToLoad.map((script, index) =>
          loadScript(script, `script${index}`)
        )
      );
    }
  }, []);

  return <h3>Footer</h3>;
};

export default Footer;
