import { Outlet } from 'react-router-dom';
import Sabath from '../organisms/asides/Sabath';
import Footer from './Footer';
import Header from './Header';

const Layout = () => (
  <div className="l-wrap">
    <div className="l-wrap__content l-content" role="document">
      <Header />
      <main
        className="l-main u-spacing--double u-padding--double--bottom"
        role="main"
      >
        <Outlet />
      </main>
      <Footer />
    </div>
    <Sabath />
  </div>
);
export default Layout;
