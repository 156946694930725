import { FC } from 'react';
import classNames from 'classnames';
import { IconLogo } from '../../atoms/Icons';

const Sabath: FC<{
  sabbath_background?: string;
  sabbath_logo?: boolean;
  sabbath_icon_class?: string;
}> = ({ sabbath_background, sabbath_logo = true, sabbath_icon_class = '' }) => (
  <aside
    className={classNames(
      'l-wrap__sabbath l-sabbath',
      { 'u-background-image--sabbath': sabbath_background },
      'js-sticky-parent js-toggle-menu'
    )}
  >
    {sabbath_background && (
      <>
        {/* <style>
      .u-background-image--sabbath::after {
        background-image: url('{{ sabbath_background }}') !important;
      }
    </style> */}
        {sabbath_logo && (
          <div className="l-sabbath__logo u-path-fill--white js-sticky">
            <IconLogo />
          </div>
        )}
      </>
    )}

    {!sabbath_background && (
      <>
        <div className="l-sabbath__logo js-sticky">
          <div
            className={classNames('l-sabbath__logo--inner', sabbath_icon_class)}
          >
            <div className="l-sabbath__logo-light u-path-fill--white">
              <IconLogo />
            </div>
            <div className="l-sabbath__logo-dark u-theme--path-fill--base">
              <IconLogo />
            </div>
          </div>
        </div>
        <div className="l-sabbath__overlay u-theme--background-color--base"></div>
      </>
    )}
  </aside>
);

export default Sabath;
