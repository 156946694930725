import { navigationItems } from './items';
import PrimaryNavigationItem from './PrimaryNavigationItem';
import routes from '../../routes';

const primaryNavigationItems: navigationItems = [
  {
    text: 'Църковен живот',
    url: routes.churchLife(),
    subnav: {
      items: [
        {
          text: 'Проекта SEED',
          url: 'https://seed.asi-bg.org/',
          isExternal: true
        },
        {
          text: 'Седмичен урок',
          url: routes.churchLife('lesson')
        },
        {
          text: 'Календар със събития',
          url: routes.churchLife('events')
        },
        {
          text: 'Общуване',
          url: routes.commune(),
          subnav: {
            items: [
              {
                text: 'Пастор онлайн',
                url: routes.commune('online')
              },
              {
                text: 'Молитвена група',
                url: 'https://www.facebook.com/groups/188820787814459/',
                isExternal: true
              },
              {
                text: 'Адвентна българска мрежа',
                url: 'https://www.facebook.com/groups/sdabg.net',
                isExternal: true
              },
              {
                text: 'Адвентен форум',
                url: 'https://www.facebook.com/groups/AdventistDiscussions',
                isExternal: true
              }
            ]
          }
        },
        {
          text: 'Проповеди, Статии',
          url: routes.churchLife('topics')
        },
        {
          text: 'Поезия',
          url: routes.churchLife('poetry')
        },
        {
          text: 'Обяви',
          url: routes.advertisement(),
          subnav: {
            items: [
              {
                text: 'Услуги/Работа',
                url: routes.advertisement('services')
              },
              {
                text: 'Покупко-Продажби/Наем',
                url: routes.advertisement('buySell')
              },
              {
                text: 'Други',
                url: routes.advertisement('other')
              }
              //добави линк в Други или в Обяви стр.
              //   text: 'Приятелство', isExternal: true, url: 'https://dvamazahristos.org'
            ]
          }
        },
        {
          text: 'Опитности от цял свят',
          url: routes.churchLife('stories')
        },
        {
          text: 'Хумор',
          url: routes.churchLife('humor')
        },
        {
          text: 'Дарения за каузи',
          url: routes.churchLife('donations')
        }
      ]
    }
  },
  // {
  //   text: 'Beliefs'
  //   // is_active: true
  // },
  {
    text: 'БГ Справочник',
    subnav: {
      items: [
        {
          text: 'Библейски учения',
          url: routes.info('biblical')
        },
        {
          text: 'Адвентни църкви',
          url: routes.info('churches')
        },
        {
          text: 'Адвентни институции',
          url: routes.info('institutions')
        },
        {
          text: 'Речник',
          url: routes.info('dictionary')
        },
        {
          text: 'Коментари на библейски стихове',
          url: routes.info('comment')
        },
        {
          text: 'Залез слънце',
          url: routes.info('sunset')
        }
      ]
    }
  },
  {
    text: 'Медии',
    url: routes.media(),
    subnav: {
      items: [
        {
          text: 'Радио',
          url: routes.media('radio')
        },
        {
          text: 'Телевизия',
          url: routes.media('tv')
        },
        {
          text: 'Издателство "Нов живот"',
          isExternal: true,
          url: 'https://newlife-bg.com/'
        },
        {
          text: 'Списание "Християнска мисъл"',
          isExternal: true,
          url: 'https://hm-aw.adventist.bg/'
        },
        {
          text: 'Онлайн курсове',
          url: routes.media('courses')
        },
        {
          text: 'Адвентисти онлайн',
          url: routes.media('bg-links')
        },
        {
          text: 'Приложения',
          url: routes.media('app')
        },
        {
          text: 'Чужди сайтове',
          url: routes.media('links')
        }
      ]
    }
  },

  {
    text: 'Ресурси',
    url: routes.resources(),
    subnav: {
      items: [
        {
          text: 'Книги',
          url: routes.resources('books')
        },
        {
          text: 'Аудио',
          url: routes.resources('audio')
        },
        {
          text: 'Видео',
          url: routes.resources('video')
        },
        {
          text: 'Музика',
          url: routes.resources('music')
        },
        {
          text: 'Презентации',
          url: routes.resources('presentation')
        }
      ]
    }
  },
  {
    text: 'Здраве',
    url: routes.health(),
    subnav: {
      items: [
        {
          text: 'Програмата New start',
          url: routes.health('new-start')
        },
        {
          text: 'Видео лекции, предавания',
          url: routes.health('video')
        },
        {
          text: 'Книги',
          url: routes.health('books')
        },
        {
          text: 'Рецепти',
          url: routes.health('recipes')
        },
        {
          text: 'Здравни институции',
          url: routes.health('institutions')
        },
        {
          text: 'Услуги',
          url: routes.health('services')
        }
      ]
    }
  },
  {
    text: 'Детски кът',
    url: routes.kids
  }
  // {
  //   text: 'Sign up now',
  //   link_class: 'is-bold'
  // }
];

const PrimaryNavigation = ({ inDrawer = false }) => (
  <nav className="c-primary-nav c-priority-nav" role="navigation">
    <ul className="c-primary-nav__list c-priority-nav__list">
      {primaryNavigationItems.map((item, i) => (
        <PrimaryNavigationItem inDrawer={inDrawer} item={item} key={i} />
      ))}
    </ul>
  </nav>
);
export default PrimaryNavigation;
