import classNames from 'classnames';
import PrimaryNavigation from './PrimaryNavigation';
import SecondaryNavigation from './SecondaryNavigation';

const DrawerNavigation = ({
  isActive,
  setActive
}: {
  isActive: boolean;
  setActive: (isActive: boolean) => void;
}) => (
  <div
    onClick={() => setActive(false)}
    className={classNames('c-drawer', 'l-grid', 'l-grid--7-col', {
      'this-is-active': isActive
    })}
  >
    <div className="c-drawer__toggle js-toggle-parent u-theme--background-color-trans--darker">
      <div className="u-icon o-icon__close">
        <span></span>
        <span></span>
      </div>
    </div>

    {/* drawer contaier */}
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
      className="l-grid-wrap--6-of-7 l-grid-item--s--6-col c-drawer__container u-spacing u-theme--background-color--darker"
    >
      <div className="c-drawer__nav">
        <div className="c-drawer__nav-primary">
          <PrimaryNavigation inDrawer={true} />
        </div>
        <div className="c-drawer__nav-secondary">
          <SecondaryNavigation setOpenDrawerMenu={setActive} />
        </div>
      </div>
    </div>
  </div>
);
export default DrawerNavigation;
