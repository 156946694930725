import classNames from 'classnames';
import { menuItem } from './items';
import { NavLink } from 'react-router-dom';
import { useState } from 'react';

const PrimaryNavigationItem = ({
  item,
  inDrawer
}: {
  item: menuItem;
  inDrawer: boolean;
}) => {
  const [isActive, setIsActive] = useState(false);

  const linkAttr = {
    target: item.isExternal ? '_blank' : undefined,
    to: item.url || '',
    href: item.url || '',
    className: `${item.link_class || ''} 
        ${classNames(
          'c-primary-nav__link',
          'u-font--primary-nav',
          'u-color--gray--dark',
          'u-theme--link-hover--base',
          'u-theme--border-color--base',

          { 'is-priority': item.priority }
        )}`
  };
  return (
    <li
      onMouseEnter={() => {
        inDrawer && setIsActive(true);
      }}
      onMouseLeave={() => inDrawer && setIsActive(false)}
      className={classNames(
        'c-primary-nav__list-item',
        {
          'has-subnav': item.subnav
        },
        { 'this-is-active': isActive }
      )}
    >
      {item.isExternal && (
        <a {...linkAttr}>
          {item.text} <i className="fa fa-external-link"></i>
        </a>
      )}
      {!item.isExternal && <NavLink {...linkAttr}>{item.text}</NavLink>}

      {item.subnav && (
        <>
          <span className="c-primary-nav__arrow c-subnav__arrow o-arrow--down u-path-fill--gray"></span>
          <ul className="c-primary-nav__subnav c-subnav">
            {item.subnav.items.map((secondary_item, j) => {
              return <SecondaryItem secondaryItem={secondary_item} key={j} />;
            })}
          </ul>
        </>
      )}
    </li>
  );
};

const SecondaryItem = ({ secondaryItem }: { secondaryItem: menuItem }) => {
  const [isActive, setIsActive] = useState(false);
  const attr = {
    href: secondaryItem.url || '',
    to: secondaryItem.url || '',
    className:
      'c-primary-nav__subnav__link c-subnav__link u-color--gray--dark u-theme--link-hover--base'
  };
  return (
    <li
      className={classNames(
        'c-primary-nav__subnav__list-item',
        'c-subnav__list-item',
        'u-background-color--gray--light',
        'u-theme--border-color--dark',
        { 'has-subnav js-this': secondaryItem.subnav },
        { 'this-is-active': isActive }
      )}
    >
      {secondaryItem.isExternal && (
        <a {...attr} target="_blank">
          {secondaryItem.text} <i className="fa fa-external-link"></i>
        </a>
      )}
      {!secondaryItem.isExternal && (
        <NavLink {...attr}>{secondaryItem.text}</NavLink>
      )}

      {secondaryItem.subnav && (
        <>
          <span
            onClick={() => {
              setIsActive((oldIsActive) => !oldIsActive);
            }}
            className={classNames(
              'c-primary-nav__subnav__arrow c-subnav__arrow o-arrow--down u-path-fill--gray js-toggle',
              { 'this-is-active': isActive }
            )}
            data-toggled="this"
            data-prefix="this"
          ></span>

          <ul className="c-primary-nav__subnav__subnav c-subnav">
            {secondaryItem.subnav.items.map((tertiary_item, k) => (
              <TertiaryItem tertiaryItem={tertiary_item} key={k} />
            ))}
          </ul>
        </>
      )}
    </li>
  );
};

const TertiaryItem = ({ tertiaryItem }: { tertiaryItem: menuItem }) => {
  const attr = {
    href: tertiaryItem.url || '',
    to: tertiaryItem.url || '',
    className:
      'c-primary-nav__subnav__subnav__link c-subnav__link u-color--gray--dark u-theme--link-hover--lighter'
  };
  return (
    <li className="c-primary-nav__subnav__subnav__list-item c-subnav__list-item u-theme--background-color--base">
      {tertiaryItem.isExternal && (
        <a {...attr} target="_blank">
          {tertiaryItem.text} <i className="fa fa-external-link"></i>
        </a>
      )}
      {!tertiaryItem.isExternal && (
        <NavLink {...attr}>{tertiaryItem.text}</NavLink>
      )}
    </li>
  );
};

export default PrimaryNavigationItem;
